<template>
  <div>
    <Header class="space-mobile"></Header>
    <div class="container space">
      <h2>Historie:</h2>
      <p>
        Jsme rodinná firma, která na českém trhu působí již od roku 2001. Už od začátku je produkce firmy zaměřena na
        zakázkovou kovovýrobu dle požadavků klienta. Jak šel, čas došlo i k inovacím a modernizaci našich postupů a
        metod. Výrobu jsme rozšířili o výrobu nerezových prvků. I v současnosti se snažíme jít s dobou a investujeme do
        modernizace naší výroby a zabýváme se také konstrukcemi pro fotovoltaické panely.
      </p>
      <p>
        Za dobu fungování naší společnosti jsme se podíleli na řadě projektů, které jsou uvedeny níže.
      </p>

      <p>Tobogány - výroba a montáž konstrukcí:</p>
      <ul>
        <li>Aqualand Moravia (venkovní tobogány)</li>
        <li>Aquapark Vyškov</li>
        <li>Koupaliště Ostrov</li>
        <li>Koupaliště Valašské Meziříčí</li>
        <li>Aquapark Kladno</li>
        <li>Koupalište Mikulov</li>
        <li>A mnoho dalších ...</li>
      </ul>
      <p>Čistírny odpadních vod:</p>
      <ul>
        <li>ČOV Modřice</li>
        <li>ÚČOV Praha</li>
        <li>ČOV Mohelnice</li>
        <li>A další...</li>
      </ul>
      <p>Úpravny vod:</p>
      <ul>
        <li>ÚV Zaječí</li>
        <li>ÚV Břeclav</li>
      </ul>
      <p>A další zakázková výroba…</p>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";

export default {
  name: "historyPage",
  components: {Footer, Header},
  data() {
    return {
      sliderPhoto: ["/photo/Pasohlávky_2.1.jpg", "/photo/Pasohlávky_3.1.jpg"],
    }
  },
  methods: {
    getPhotos: function () {
      return this.sliderPhoto
    }
  }
}
</script>

<style scoped>
.space {
  min-height: 70vh;
  padding-top: 12rem;
}

@media screen and (max-width: 600px) {
  .space-mobile {
    padding-bottom: 8rem;
  }

  .space {
    min-height: 50vh;
    padding-top: 2rem;
  }
}
</style>